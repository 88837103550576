.login {
  width: 100vw;
  height: 100vh;
  position: relative;

  // opacity: .9;
  .logo{
    width: 174px;
    height: 41px;
    position: absolute;
    top: 59px;
    left: 39px;
    z-index: 5;
  }

  .loginBg {
    // width: 800px;
    width: 400px;
    height: 461px;
    // background: url('../../assets//images/loginImg/logopanel.png') no-repeat center center;
    background-size: cover;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #dddddd;

    .loginIcon {
      width: 80px;
      height: 80px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    .txtIcon {
      width: 76px;
      height: 24px;
      position: absolute;
      top: 16px;
      right: 80px;
    }

    .accountLogin {
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
      // padding-left: 80px;
      // padding-right: 80px;
      // margin-left: 71px;
      // margin-right: 71px;
      width: 256px;
      // color: #fff;
      color: #1F1F20;

      .register {
        display: inline-block;
        font-size: 40px;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Bold;
        font-weight: 700;
        // color: #ffffff;
        color: #1F1F20;
        border-left: 4px solid #1F1F20;
        padding-left: 16px;
        padding-bottom: 2px;
        margin-bottom: 48px;
        height: 47px;
        line-height: 47px;
      }

      .scanRegister {
        color: #fff;
        font-size: 16px;
        // white-space: nowrap;
        position: absolute;
        top: -24px;
        left: 50%;
        // right: 50%;
        transform: translateX(-50%);
      }

      .scanImg {
        width: 256px;
        height: 256px;
        border: 1px solid #1F1F20;
      }

      .explain {
        position: absolute;
        // top: -24px;
        left: 50%;
        bottom: -24px;
        // right: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 14px;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
        font-weight: 400;
        color: #b0b0b0;

        .explainBold {
          // font-weight: 600;
          // color: #fff;
          color: #000;
        }
      }

      .forgetThePassword {
        font-size: 14px;
        text-align: center;
        width: 256px;
        cursor: pointer;
      }

      .verificationCode {
        position: absolute;
        top: 5px;
        right: 0;
      }

      // /deep/.ant-input {
      //   padding-top: 8px;
      //   padding-bottom: 8px;
      //   height: 40px;
      // }

      // /deep/.ant-input-password {
      //   height: 40px;
      // }
    }
  }

  .copyright {
    font-size: 14px;
    color: #B0B0B0;
    position: absolute;
    bottom: 40px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
  }
}

.login::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  // /* 加载背景图 */
  // background-image: url('../../assets/images/loginImg/bg-img.png');
  // /* 背景图垂直、水平均居中 */
  // background-position: center center;
  // /* 背景图不平铺 */
  // background-repeat: no-repeat;
  // /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  // background-attachment: fixed;
  // /* 让背景图基于容器大小伸缩 */
  // background-size: cover;
  // /* 设置背景颜色，背景图加载过程中会显示背景色 */
  // background-color: #393939;
  // opacity: .9;
}
@primary-color: #0052D9;@layout-body-background: #f1f2f5;